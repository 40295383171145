/* You can add global styles to this file, and also import other style files */
body {
  position: relative;
  min-height: 100vh;
}

body.login {
  background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url("./assets/images/SplashPage.PNG");
  background-position: center;
  background-size: cover;
  width: auto;
  background-repeat: no-repeat;
}

body.dashboard {
  background-color: #f1f1f1;
}

body.dashboard footer {
  background-color: #000;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.grabbable-pointer {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.grabbable-pointer:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.f-btn-animation {
  transition: all 0.15s ease;
}
.f-btn-animation:hover {
  transform: scale(1.02) translateY(-1.5px);
}
.f-btn-animation:active {
  transform: scale(0.98) translateY(0px);
}

.f-btn.f-xs.has-gif img {
  margin: 0 2px;
}

.f-btn.f-xs.has-gif.smaller img {
  height: 12px;
}

.f-btn.btn-xs img {
  width: auto;
  max-height: 16px;
}

/* =========================== .f-table Collapse - media queries [start] */
/* === XXL Table === */
@media (max-width: 1550px) {
  .f-table.table-responsive-xxl thead {
    display: none;
  }
  .f-table.table-responsive-xxl,
  .f-table.table-responsive-xxl tbody,
  .f-table.table-responsive-xxl tr,
  .f-table.table-responsive-xxl td {
    display: block;
    min-width: 100%;
    border-bottom: 0.5px solid #908d8d;
  }
  .f-table.table-responsive-xxl tr td {
    padding-left: 50% !important;
    text-align: left;
    position: relative;
  }
  .f-table.table-responsive-xxl td::before {
    content: attr(data-label);
    position: absolute;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    left: 0;
    width: 50%;
    padding-left: 15px;
    font-weight: bold;
    text-align: left;
  }
  .f-table.table-responsive-xxl tr td:last-child {
    padding-right: 5px;
  }

  .f-table.table-responsive-xxl tr td:last-child {
    padding-right: 5px !important;
  }
}
/* === XL Table === */
@media (max-width: 1200px) {
  .f-table.table-responsive-xl thead {
    display: none;
  }
  .f-table.table-responsive-xl,
  .f-table.table-responsive-xl tbody,
  .f-table.table-responsive-xl tr,
  .f-table.table-responsive-xl td {
    display: block;
    min-width: 100%;
    border-bottom: 0.5px solid #908d8d;
  }
  .f-table.table-responsive-xl tr td {
    padding-left: 50% !important;
    text-align: left;
    position: relative;
  }
  .f-table.table-responsive-xl td::before {
    content: attr(data-label);
    position: absolute;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    left: 0;
    width: 50%;
    padding-left: 15px;
    font-weight: bold;
    text-align: left;
  }
  .f-table.table-responsive-xl tr td:last-child {
    padding-right: 5px;
  }

  .f-table.table-responsive-xl tr td:last-child {
    padding-right: 5px !important;
  }
}
/* === SM Table === */
@media (max-width: 576px) {
  .f-table.table-responsive-small thead {
    display: none;
  }
  .f-table.table-responsive-small,
  .f-table.table-responsive-small tbody,
  .f-table.table-responsive-small tr,
  .f-table.table-responsive-small td {
    display: block;
    min-width: 100%;
    border-bottom: 0.5px solid #908d8d;
  }
  .f-table.table-responsive-small tr td {
    padding-left: 50% !important;
    text-align: left;
    position: relative;
  }
  .f-table.table-responsive-small td::before {
    content: attr(data-label);
    position: absolute;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    left: 0;
    width: 50%;
    padding-left: 15px;
    font-weight: bold;
    text-align: left;
  }
  .f-table.table-responsive-small tr td:last-child {
    padding-right: 5px;
  }

  .f-table.table-responsive-small tr td:last-child {
    padding-right: 5px !important;
  }
}
.f-table-input-sm {
  width: 70px;
}
/* text align [xl] */
@media (max-width: 768px) {
  .f-table.table-responsive-xl td {
    text-align: right !important;
  }
}
@media (max-width: 576px) {
  .f-table.table-responsive-xl tr td {
    font-size: 12px;
  }
}
/* stext align [sm]  */
@media (max-width: 576px) {
  .f-table.table-responsive-small tr td {
    font-size: 12px;
  }
  .f-table.table-responsive-small td {
    text-align: right !important;
  }
}

/* table elements custom modifications */
/* Lookup */
@media (max-width: 576px) {
  .f-table.table-responsive-small.lookup-table tr td {
    padding-left: 33% !important;
    padding-right: 15px;
  }
}
/* =========================== .f-table Collapse - media queries [end] */

.sort-table-heading,
.no-sort-table-heading {
  color: #4176b0;
}

.sort-table-heading:hover {
  text-decoration: underline;
}

.f-table .td-max-width-150 {
  max-width: 150px !important;
}

.all-req-modal-col-width .td-w-6 {
  width: 6%;
}
.all-req-modal-col-width .td-w-10 {
  width: 10%;
}
.all-req-modal-col-width .td-w-20 {
  width: 20%;
}
.all-req-modal-col-width .td-w-35 {
  width: 35%;
}

/* Small Header Dark Grey */

.small-header-dark {
  display: flex;
  align-items: center;
  background-color: #413e3d;
  color: #fff;
  justify-content: space-between;
  z-index: 3;
}
.small-header-dark.table-header-dark {
  padding: 8px 12px;
  border-radius: 3px 3px 0 0;
}

/* Large Header Dark Grey */
.dark-header-lg {
  display: flex;
  height: 55px;
  align-items: center;
  background-color: #413e3d;
  color: #fff;
  justify-content: space-between;
  z-index: 3;
}

/* ====== DRAGULA CSS ====== */
/* in-flight clone */
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
  pointer-events: none;
}
/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}
/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}

/* ====== PAGINATION ====== */
.fender-pagination .pagination > li > .page-link {
  margin: 0 1px;
  background-color: #4376ac;
  border: none;
  font-size: 16px;
  color: #fff;
  font-family: "FuturaPT-Book";
}

.fender-pagination .pagination > li > .page-link:hover {
  background-color: #214b78;
}
.fender-pagination .pagination > li.active > .page-link {
  background-color: #214b78;
}

.fender-pagination.dark .pagination > li > .page-link {
  background-color: #413e3d;
  color: #fff;
}

.fender-pagination.dark .pagination > li.disabled > .page-link {
  color: #ddd;
}

.fender-pagination.dark
  .pagination
  > li:not(.active):not(.disabled)
  > .page-link:hover {
  background-color: #666;
}
.fender-pagination.dark .pagination > li.active > .page-link {
  background-color: #aaa;
}

input[type="checkbox"]:checked {
  position: relative;
  background: url(assets/images/check-mark.svg) center no-repeat, #4176b0 !important;
}

input[type="checkbox"]#activeCheckbox {
  appearance: none;
  height: 30px;
  width: 30px;
  margin-top: -3px;
  background-color: #d5d5d5;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  box-shadow: none;
  margin-right: 15px;
  transition: all 0.15s ease;
}
input[type="checkbox"]#activeCheckbox:hover {
  background-color: #a5a5a5;
}
input[type="checkbox"]#activeCheckbox:checked {
  background-color: #6aaf5b !important;
}

select {
  display: none;
  background-image: url(assets/images/chevron-down.svg) !important;
}

.input-with-chevron {
  padding-left: 7px;
  background: url(assets/images/chevron-down.svg) no-repeat scroll;
  background-size: 12px;
  background-position: right 12px center;
}

.contained-video-modal video {
  max-width: 100%;
}

/* For containing images inside modal components */
.images-contained-modal img {
  max-width: 100% !important;
  height: auto;
}

/* Add Asterisk for Required Labels */
label.required:after {
  content: " *";
  color: #cc3333;
}

/* Header */
.banner {
  background-color: #cc3333;
}
.banner.red {
  background-color: #cc3333;
}
.banner.brown {
  background-color: #c09853;
}
.banner.green {
  background-color: #6aaf5b;
}
.banner.blue {
  background-color: #4176b0;
}
.logo-container {
  margin: 30px 0;
}
.logo-image {
  max-width: 225px;
}

.supplier-text {
  margin-top: 18px;
  margin-left: 20px;
  font-size: 38px;
}

@media (max-width: 768px) {
  .logo-container {
    margin: 15px 0;
  }
  .logo-image {
    max-width: 120px;
  }
  .supplier-text {
    margin-left: 10px;
    font-size: 26px;
  }
}

@media (max-width: 576px) {
  .logo-container {
    flex-direction: column;
  }
  .logo-image {
    margin-bottom: 0;
    margin-top: 20px;
  }
  .supplier-text {
    margin-top: -10px;
    margin-left: 50px;
    font-size: 22px;
  }
}

/* Footer */
.footer-safespace {
  padding-bottom: 140px;
}
.font-size-13 {
  font-size: 13px;
}
.font-size-14 {
  font-size: 14px;
}
.font-size-18 {
  font-size: 18px;
}

/* Login */
.width-auth {
  width: 350px;
}
.padding-bottom-auth {
  padding-bottom: 200px;
}

.soon-banner {
  position: absolute;
  left: 0;
  width: 100%;
  margin-top: 25px;
}

/* Browser Support */
.max-width-browser-support {
  max-width: 600px;
}

/* Main Header */
.header {
  background-color: #cc3333;
  height: min-content;
}
.header.red {
  background-color: #cc3333;
}
.header.brown {
  background-color: #c09853;
}
.header.green {
  background-color: #6aaf5b;
}
.header.blue {
  background-color: #4176b0;
}
.header .logo-image {
  max-width: 80px;
}
.header .logo-text {
  margin-top: 10px;
}
.header .guitar-head-image {
  max-width: 40px;
  height: auto;
}
.header .dropdown-menu {
  margin-top: 10px;
  right: 0;
}
.header .badge-position {
  position: absolute;
  top: 3px;
  left: 24px;
  z-index: 1;
}
.header .dropdown-search {
  min-width: 75vw;
}
.header .search-span {
  padding-bottom: 30px;
}
@media (min-width: 992px) {
  .header .dropdown-search {
    display: none;
  }
}
@media (max-width: 576px) {
  .header .logo-container-main {
    flex-direction: column;
  }
  .header .logo-text {
    font-size: 12px;
    margin-top: -4px;
    margin-right: -40px;
  }
  .header .dropdown-search {
    min-width: 90vw;
    position: absolute;
    top: 100%;
    right: -50%;
  }
}

@media (max-width: 320px) {
  .header .dropdown-search {
    min-width: 90vw;
    position: absolute;
    top: 100%;
    right: -70%;
  }
}

.header .header-parent {
  display: flex;
  justify-content: space-between;
}
.header .left-item,
.header .right-item {
  flex-grow: 1;
  flex-basis: 0;
}

/* Truncate Flex */
.long-and-truncated-parent {
  flex: 1;
  min-width: 0;
}

.long-and-truncated {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.max-height-3lh {
  white-space: inherit;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.td-max-trunc {
  max-width: 400px !important;
}
@media (max-width: 1450px) {
  .td-max-trunc {
    max-width: 300px !important;
  }
}
@media (max-width: 1300px) {
  .td-max-trunc {
    max-width: 200px !important;
  }
}

/* Main Menu */
.menu-bar {
  width: 100%;
  background-color: black;
  display: flex;
  position: relative;
}

.main-menu-list {
  display: flex;
  padding: 0 !important;
  margin: 0 !important;

  list-style: none;
}

.main-menu-list li:hover {
  cursor: pointer;
}

.main-menu-list a {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 1;
  flex-direction: column;
  color: white;
  text-decoration: none;
  height: 100%;
  width: 100px;
  padding: 10px 0;
  border-right: 1px solid #413e3d;
  transition: all 0.2s ease;
}
.main-menu-list a:hover {
  background-color: #413e3d;
}

.main-menu-list .has-events {
  background-color: #336699;
}
.main-menu-list .has-events:hover {
  background-color: #336699;
}
.main-menu-list a:hover {
  background-color: #413e3d;
}

.main-menu-list i {
  margin-bottom: 8px;
}

.menu-bar .collapse-button {
  display: none;
  align-items: center;
  justify-content: center;
  align-self: center;
  border-radius: 5px;
  width: 40px;
  height: 30px;
  margin: 15px;
  border: 1px solid #4e4e4e;
  background-color: #333;
  transition: all 0.2s ease;
}

.main-menu-list li:hover > .sub-menu-items {
  opacity: 1;
  visibility: visible;
}

.custom-page-nav-icon {
  height: 23px;
  width: 23px;
}

.menu-bar .collapse-button:hover {
  border: 1px solid #4e4e4e;
  background-color: #4e4e4e;
}

.menu-bar .collapse-button i {
  color: white;
}

.sub-menu-items {
  position: absolute;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
  z-index: 10;
  opacity: 1;
  visibility: hidden;
}

.sub-menu-items ul {
  list-style: none;
  background-color: white;
  padding: 3px 3px 1px 3px;
}

.sub-menu-items ul li {
  margin-bottom: 3px;
  background-color: white;

  transition: none;
}
.sub-menu-items ul li a {
  border: none;
  padding: 10px 15px;
  min-width: 200px;
  display: flex;
  align-items: center;
  justify-content: start;
  text-align: start;
  flex-direction: row;
  cursor: pointer;
  transition: none;
}

.sub-menu-items li:hover {
  background-color: #4e4e4e;
  color: white;
}
.sub-menu-items li:hover i.f-icon::before {
  background-position-y: -18px;
}

.subitems-down-icon {
  position: absolute;
  bottom: 0;
}

@media (max-width: 768px) {
  .menu-bar .collapse-button {
    display: flex;
  }

  .main-menu-list {
    display: none;
  }

  .main-menu-list.is-open {
    background-color: black;
    width: 100%;
    display: block;
    position: absolute;
    left: 0;
    top: 100%;
    z-index: 10;
  }

  .main-menu-list a {
    display: flex;
    position: relative;
    flex-direction: row;
    border-right: none;
    border-top: 1px solid #413e3d;
    width: 100%;
    justify-content: flex-start;
    padding: 12px 18px;
  }

  .main-menu-list i {
    margin-right: 10px;
  }

  .subitems-down-icon {
    position: absolute;
    top: 35%;
    right: 10px;
  }
}

@media (min-width: 768px) {
  .main-menu-list-mobile {
    display: none;
    visibility: hidden;
  }
}

.main-menu-list-mobile {
  position: absolute;
  width: 100%;
  z-index: 10;
  background-color: #333;
}
.main-menu-list-mobile ul {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.main-menu-list-mobile li {
  list-style: none;
  margin: 0;
  padding: 10px 0;
  border-bottom: 1px solid #4e4e4e;
  cursor: pointer;
}
.main-menu-list-mobile li:hover {
  background-color: #4e4e4e;
}
.main-menu-list-mobile li.has-events {
  background-color: #336699;
}
.main-menu-list-mobile li.has-events:hover {
  background-color: #4580bf;
}

.main-menu-list-mobile-lables {
  color: white;
  margin: 0 20px;
}

.main-menu-subitem-list-mobile {
  background-color: #999;
}
.main-menu-subitem-list-mobile li {
  border-bottom: 1px solid #787878;
}
.main-menu-subitem-list-mobile li:hover {
  background-color: rgb(166, 166, 166);
}

.main-menu-subitem-list-mobile .main-menu-list-mobile-lables {
  margin-left: 35px;
}

@media (max-width: 768px) {
  .sub-menu-items {
    position: static;
    opacity: 1;
    visibility: visible;
  }

  .sub-menu-items ul {
    visibility: hidden;
    height: 0px;
    background-color: #413e3d;
    color: #fff;
  }

  .sub-menu-items ul li {
    background-color: #413e3d;
  }

  .sub-menu-items li:hover {
    position: relative;
    height: 100%;
  }
}

/* Select Supplier */

.select-supplier .input {
  width: 300px;
}

.select-supplier .loader-container {
  cursor: auto;
  padding: 0;
}

.selected-dealer-container {
  background-color: #f1e6b2;
}

@media (max-width: 576px) {
  .select-supplier .input {
    width: 100%;
  }
}

/* Notification Dialog */

.notifications-dialog_container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1060;
  width: 100%;
  height: 100%;
}

.blockscreen {
  position: relative;
  background-color: #000;
  opacity: 0.5;
  width: 100%;
  height: 100%;
}

.notifications-dialog {
  position: absolute;
  width: 100%;
  z-index: 1061;
  top: 180px;
  background-color: #000;
  text-align: left;
  color: #fff;
  padding: 35px 15%;
  opacity: 1;
}

.blockscreen {
  opacity: 0.5;
}
.notifications-dialog {
  opacity: 1;
}

/* Fender Tone Loading */
div.inline-loading {
  display: inline-block;
}

.inline-loading-container-sm {
  display: inline-block;
  max-height: 20px;
  max-width: 20px;
}

.inline-loading-container-sm img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

/* Site Messages */
.site-message {
  width: 992px;
  border: 1px solid #664d03;
}

/* Contact Us */
.contact-us-body .department-message {
  background-color: #fbfbfb;
}
.contact-us-body .comment-indicator {
  font-size: 18px;
  color: #908d8d;
}

/* Toast Container */
.toast-container {
  z-index: 5;
}

/* ====== WIDGETS ======= */
.card {
  transition: all 0.5s ease-in;
}

.widget {
  height: 315px;
  overflow: hidden;
  transition: all 0.2s ease-out;
}

.widget.double-height {
  height: 690px;
}

.widget.on-collapse {
  height: 0;
  opacity: 0;
  padding: 0 !important;
}

.fullscreen {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  transform: scale(0);
  animation: to-fullscreen 0.3s ease-out forwards;
  z-index: 4;
}

@keyframes to-fullscreen {
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    width: 100vw;
    height: 100vh;
    transform: scale(1);
  }
}

@media (max-width: 1200px) {
  .widget.doc-widget {
    height: auto;
    max-height: auto;
  }
  .widget.announcements-widget {
    height: auto;
    max-height: 500px;
  }
  .widget.lookup-widget {
    height: auto;
  }
}

.widget-controls-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 5px;
  margin-left: 10px;
  height: 24px;
  width: 24px;
  border-radius: 2px;
  transition: all 0.2s ease;
}

.widget-controls-btn:hover {
  background-color: #908d8d;
}

.td-max-width {
  max-width: 100%;
}

.see-more-container {
  background-color: #fff;
  position: absolute;
  padding: 5px 12px;
  bottom: 0px;
  left: 0px;
  border-top-right-radius: 10px;
  transition: all 0.1s ease;
}
.see-more-container:hover {
  filter: drop-shadow(4px -4px 4px rgba(0, 0, 0, 0.35));
}

.search-selected {
  background-color: #908d8d;
  color: white;
}
.loading-table {
  text-align: center;
}

.search-btn {
  transition: all 0.2s ease;
}

.search-btn:hover {
  filter: brightness(120%);
}

.search-container {
  visibility: visible;
  max-height: 70px;
  transition: all 0.2s ease;
}

.search-container.search-hidden {
  visibility: hidden;
  transform: scaleY(0.25);
  max-height: 0;
}

.description-min-height {
  min-height: 40px;
}

.description-overflow {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.image-thumbnail {
  height: 60px;
  min-width: 115px;
  border-radius: 3px;
  display: block;
  position: relative;
  overflow: hidden;
}
.image-thumbnail img {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.modal-dialog.video-modal {
  max-width: 935px !important;
  width: 100%;
  margin: 0 auto;
}

.wide-modal-dialog {
  width: 100%;
  max-width: 1080px;
  margin: 30px auto;
}

.wide-modal-dialog .modal-content {
  width: 100%;
  height: 100%;
}

.new-annoncement-tag {
  color: #cc3333;
  font-weight: 900;
  font-size: 11px;
  font-style: italic;
  vertical-align: top;
}

/* Docuemnts */
.document-page-table .table-btn-width {
  width: 130px;
}
@media (max-width: 768px) {
  .document-page-table .table-btn-width {
    width: auto;
  }
}

/* Video Thumbnail */
.video-thumbnail .video-thumbnail_container {
  display: flex;
  justify-content: center;
  cursor: pointer;
  position: relative;
}
.video-thumbnail_container video:hover {
  transition: all 0.2s ease;
}
.video-thumbnail_container video:hover {
  transform: scale(1.02) translateY(-1px);
}
.video-thumbnail_container video:active {
  transform: scale(0.99);
}
.video-thumbnail .video-thumbnail_image-container {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}
.video-thumbnail header {
  text-align: center;
}
.video-thumbnail .btn-ghost {
  position: absolute;
  padding: 4px 10px;
  top: 0px;
  right: 5px;
  color: #fff;
  border-radius: 10px;
  transition: all 0.2s ease;
}
.video-thumbnail .btn-ghost:hover {
  transform: scale(1.02) translateY(-2px);
}
.video-thumbnail .btn-ghost:active {
  transform: translateY(4px);
}
.video-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  /* background-color: #cc3333; */
  background-color: rgba(0, 0, 0, 0.3);
}
.video-overlay:hover {
  opacity: 1;
}

/* Help Section */
.edit-question-btn {
  padding: 3px 10px;
}
.answers-section {
  overflow: hidden;
  max-height: 0;
  padding-top: 0;
  padding-bottom: 0;
  transition: max-height 0.3s ease-out;
}
.answers-section.show {
  max-height: 1500px;
  transition: max-height 0.5s ease-in-out;
}
.question-separator {
  border: none;
  background: radial-gradient(#000000, 30%, #ffffff);
  opacity: 15%;
}
.category-chevron {
  transition: all 0.4s ease-in-out;
}
.category-chevron.open {
  transform: rotate(180deg);
}
.answer-container {
  overflow: hidden;
  max-height: 0;
  padding: 0 20px;
  transition: max-height 0.25s ease;
}
.answer-container div {
  padding-top: 6px;
  padding-bottom: 12px;
}
.answer-container.show {
  max-height: 1500px;
  transition: max-height 0.5s ease-in;
}
.empty-section-text {
  color: #908d8d;
}

/* UserProfile */
.user-profile .text-darkgrey {
  color: #908d8d;
}

.user-profile .email-text {
  font-size: 18px;
}

.user-profile .logo img {
  width: 100px;
  padding-top: 10px;
  transform: skewY(-12deg);
}

.user-profile .logo-xs img {
  width: 60px;
  padding-top: 10px;
  transform: skewY(-12deg);
}

@media (max-width: 576px) {
  .user-profile .logo {
    display: none;
  }
}
@media (min-width: 576px) {
  .user-profile .logo-xs {
    display: none;
  }
}

/* Edit Select */
.edit-select-position {
  margin: 2px 2px 3px 8px;
}

/* === Account Maintenance === */
/* Tables */

.fa-white {
  color: #fff;
}
.fa-blue {
  color: #336699;
}
.fa-green {
  color: #6aaf5b;
}
.fa-dark-grey {
  color: #908d8d;
}
.fa-silver-grey {
  color: #cccccc;
}
.btn-xs {
  font-size: 14px;
  padding: 4px 8px;
}
.btn-xs .f-icon {
  margin-right: 4px;
}
.btn-std {
  border-radius: 2px;
  padding: 1px 5px;
  font-size: 14px;
}
.btn-std.std-disable {
  pointer-events: none;
  background-color: #cccccc;
}
.btn-std .fa-solid {
  margin: 0;
}
.btn-std.max-width {
  width: 100%;
  max-width: 90px;
}

.btn-std.bg-grey {
  background-color: #ccc;
  transition: all 0.1s;
}
.btn-std.bg-grey:hover {
  background-color: #eee;
}

/* Light Button - Outline Light */
.f-btn-outline-light {
  background-color: #fff;
  border: 2px solid #ccc;
}

.f-btn-outline-light:hover {
  background-color: #eee;
}

.inactive-btn {
  background-color: #cccccc;
  color: transparent;
}

.drag-mobile-row {
  padding-left: 15px !important;
  text-align: center !important;
}

.invalid-font-color {
  color: #dc3545 !important;
}

.required-asterisk {
  color: #cc3333;
}

.description-counter {
  display: flex;
  justify-content: right;
}

/* Reactive Image Selector */
.img-select-image-container {
  position: relative;
}

.image-crop-uploader .img-select-image-container img {
  max-width: 100%;
  max-height: 150px;
  border: solid 1px #ccc;
  padding: 10px;
  border-radius: 6px;
}

.img-cropper-width {
  width: 250px;
}

.cropArea {
  background: #e4e4e4;
  overflow: hidden;
  height: 300px;
  padding: 10px;
}

.crop-area {
  background: #e4e4e4;
  overflow: hidden;
  padding: 10px;
}

.crop-area .resize .square {
  float: initial;
  margin-right: initial;
}

.cropped-preview {
  border: 2px dashed #d6d6d6;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
.cropped-preview img {
  max-height: 400px;
  max-width: 260px;
}

.buttons-footer {
  margin: 10px 0;
}
.buttons-footer .btn {
  margin-left: 5px;
}

@media (min-width: 767px) and (max-width: 990px) {
  .cropped-preview img {
    max-height: 400px;
    max-width: 450px;
  }
}

@media (min-width: 990px) {
  .cropArea {
    height: 420px;
  }
  .cropped-preview {
    height: 420px;
  }

  .cropped-preview img {
    max-height: 400px;
    max-width: 475px;
  }
}

/* Purchase Orders */
.po .input-container {
  width: 320px;
  max-width: 400px;
}
.po .count-banner {
  padding: 15px 20px;
  margin: 0 -16px;
  background-color: #ccc;
}

.po .clear-filter-btn {
  height: 100%;
  background-color: white;
  border: 1px solid #ccc;
  transition: all 0.2s ease;
}
.po .clear-filter-btn:hover {
  background-color: #eee;
}

.po .f-btn.btn-std {
  padding: 5px;
  line-height: 0.75;
}

.po table.f-table tr th {
  font-size: 14px;
}
.po table.f-table tr td {
  font-size: 14px;
}

.po table.f-table th.width-120 {
  width: 120px;
}

.po-multi-select {
  min-width: 270px !important;
}

.po-multi-select-separator {
  opacity: 10%;
}

.po-table-edited-line-icon {
  position: absolute;
  left: -60px;
  bottom: 0px;
  transform: scale(85%);
}

@media (max-width: 470px) {
  .po-multi-select {
    min-width: 100% !important;
  }
}

@media (max-width: 576px) {
  .po .input-container {
    width: 100%;
    max-width: 100%;
  }
}

/* New UI Select */

.new-ui-small {
  width: auto;
  padding: 5px;
  font-size: 14px !important;
}

.new-ui-small.table-min-width-90 {
  padding-right: 20px;
  min-width: 90px !important;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.new-ui-small-img img {
  margin: 5px;
  width: 25px;
  height: 25px;
  object-fit: contain;
  overflow: hidden;
}

.new-ui-select-dropdown {
  max-height: 450px;
}

.dropdown-menu-250 {
  width: 250px;
}

.new-odd-select {
  background-color: #f1f1f1;
}
.new-ui-select-selected {
  background-color: #4176b0;
  color: white !important;
}
.new-odd-select:hover {
  background-color: #f1f1f1;
  filter: brightness(97%);
}
.new-ui-select-selected:hover {
  background-color: #4884c4;
  color: white;
}

/* PO Date Range DropDown Filter */
.po-date-range-input {
  width: 145px;
}

/* Purchase Orders Details View */
.bg-gray-silver {
  background-color: #cccccc;
}

.acc-rej-select-container {
  height: 35px;
}
.acc-rej-select {
  width: 250px;
}

.invisible-date-input {
  width: 0px;
  padding: 0px;
  border: none;
  /* visibility: hidden; */
}

.table-onedit-input-tooltip-sm {
  /* width: 100px;
  overflow: visible;
  white-space: nowrap; */
  max-width: 200px;
  width: max-content;
}

/* Show More Component */
.show-more-less-button {
  font-size: 14px;
}

/* NGX Bootstrap - TimePicker */
.ngx-timepicker button {
  border: 1px solid #ced4da;
}
.text-pre-line {
  white-space: pre-line;
}

/* ===== Events ====== */

.events-select-type {
  max-width: 295px;
}

/* Pixel Stepper */

.pixel-stepper-container {
  width: 442px;
  text-align: center;
}
.pixel-stepper {
  display: flex;
  align-items: center;
}
.pixel-stepper__input {
  width: 60px;
  max-width: 60px;
  text-align: center;
}
.sub-button {
  background: #afafaf;
  border-color: #afafaf;
  color: #fff;
}
.add-button {
  background-color: #5ea1f5;
  border-color: #5ea1f5;
  color: #fff;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

color-sketch {
  max-height: 300px;
}

color-sketch > div {
  width: 300px;
  background-color: white;
}

.color-sketch-container {
  max-height: 350px;
}

/* ============== Events =============== */

/* == Sidebar == */
.user-event-container {
  background-color: #fff;
  box-shadow: inset 0 7px 9px -7px rgba(0, 0, 0, 0.2);
}

.user-event-sidebar {
}
.user-event-sidebar ul {
  list-style: none;
  padding: 0;
}
.user-event-sidebar li :hover {
  background-color: lightgray;
}

.user-event-sidebar .menu-item {
  text-transform: uppercase;
  font-weight: bold;
  padding: 10px;
  text-decoration: none;
  color: black;
}

.user-event-sidebar-item.selected > label {
  display: inline-block;
  position: relative;
}
.user-event-sidebar-item.selected > label:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 0;
  border-bottom: 2px solid #ee0d0d;
}
.user-event-sidebar-item.selected:hover {
  text-decoration: none;
}

.event-page-sidebar-wrapper {
  display: block;
}

@media screen and (max-width: 768px) {
  .event-page-sidebar-wrapper {
    display: none;
  }
}

.event-page-sidebar-wrapper-sm {
  position: relative;
}
.event-page-sidebar-wrapper-sm .user-event-sidebar {
  position: absolute;
  display: flex;
  top: 0;
  left: -120%;
  z-index: 200;
  width: 100%;
  background-color: #fff;
  transition: all 0.2s ease;
}
.event-page-sidebar-wrapper-sm .user-event-sidebar.active {
  left: 0;
}

.event-page-sidebar-toggle-btn {
  display: block;
}

@media screen and (min-width: 768px) {
  .event-page-sidebar-toggle-btn {
    display: none;
  }
}

.left-nav-wrapper {
  background-color: #fff;
  /* min-width:260px;
  padding:0px 0px 1em 24px */
}
@media screen and (min-width: 768px) {
  .left-nav-wrapper {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .left-nav-wrapper {
    position: absolute;
    width: 100%;
    height: calc(100vh - 94px);
    z-index: 300;
    left: -100%;
    padding-left: 0;
  }
  .sidebar-open {
    overflow: hidden;
  }
  .sidebar-open .header {
    margin-bottom: 20px;
  }
  .sidebar-open .left-nav-wrapper {
    left: 0;
    overflow: auto;
  }
  .sidebar-open .main {
    height: calc(100vh - 134px);
    overflow: hidden;
  }
  .content {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    padding: 0 10px;
  }
}

/* == Text Content == */
.ose-page__content--text {
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* == Image Content == */
.ose-page__content--image {
  background-position-x: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
}
.ose__page--img {
  object-fit: cover;
  max-width: 100%;
}

.ose__page--img.full-width--img {
  width: 100%;
}

/* == Video Content == */
.ose-page__content--video {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 5px;

  background-position-x: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
}
.ose-page__content--video > div {
  padding: 10px 15px;
}

.ose-page__content--video > div.text-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.ose-page__content--video > div.text-container > div.title {
  font-weight: 500;
}

.bmpui-ui-watermark {
  background-size: 4em;
  /* margin: unset; */
  background-image: url(assets/images/fender-white.svg) !important;
  margin-top: 0em;
  right: 0;
  /* bottom: 0 !important; */
  /* top: auto; */
}

.iframe-container {
  position: relative;
}

.close-btn {
  position: absolute;
  top: -16px;
  right: -24px;
  cursor: pointer;
}

@media (max-width: 576px) {
  .ose-page__content--video {
    display: block;
  }

  /*  Events - Video Thumbnail */
  .video-thumbnail {
    display: inline-block;
    height: 100%;
    width: 100%;
  }
  .video-thumbnail .video-thumbnail_container {
    cursor: pointer;
    text-align: center;
    position: relative;
    height: 92%;
    width: 100%;
  }

  .video-thumbnail .video-thumbnail_image-container {
    background-repeat: no-repeat;
    margin: auto;
    background-size: contain;
    background-position: 50% 50%;
  }

  .video-thumbnail header {
    text-align: center;
  }
  .video-thumbnail .f-btn {
    position: absolute;
    top: 5px;
    right: 37px;
  }

  .video-thumbnail .f-btn:active {
    left: initial;
    top: 6px;
    right: 38px;
  }
}

/* BITMOVIN COMPONENTS */
#video-thumbnail_container {
  height: inherit;
  position: relative;
}
.additional-tile-block--circle-hover,
.video-thumbnail_container--black {
  background: black;
}

.additional-tile--video-thumbnail {
  width: 100%;
  height: 100%;
  position: absolute;
}
.video-thumbnail-image {
  width: 100%;
  object-fit: cover;
  cursor: pointer;
}

.circle-progress-bar {
  position: absolute;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.additional-tile-block--circle,
.additional-tile-block--circle-hover {
  width: 100% !important;
}

.additional-tile-block--circle-hover {
  position: relative;
}

.additional-tile-block--circle-hover,
.additional-tile-block--circle-hover:hover img,
.circle-progress-bar,
.circle-video-thumbnail-hover .circle-progress-bar,
.circle-video-thumbnail,
.circle-video-thumbnail-hover,
.circle-video-thumbnail-hover:hover,
.circle-video-thumbnail-hover:hover img,
.circle-video-thumbnail-hover:hover .circle-progress-bar,
.video-thumbnail--circle-progressbar {
  transition: 0.5s ease;
  -webkit-transition: 0.5s ease;
  -moz-transition: 0.5s ease;
}

.circle-video-thumbnail,
.circle-video-thumbnail-hover,
.circle-video-thumbnail-hover:hover {
  display: grid;
  height: inherit;
}

.additional-tile-block--circle,
.additional-tile-block--circle-hover:hover,
.circle-video-thumbnail:hover,
.circle-video-thumbnail-hover:hover {
  cursor: pointer;
}

.circle-video-thumbnail,
.circle-video-thumbnail-hover {
  justify-items: center;
  align-items: center;
}

.additional-tile--video-thumbnail,
.video-thumbnail--circle-progressbar {
  grid-row: 1;
  grid-column: 1/2;
}

.additional-tile-block--circle-hover:hover img,
.circle-video-thumbnail-hover:hover,
.circle-video-thumbnail-hover:hover img {
  opacity: 0.8;
}

.additional-tile-block--circle-hover .circle-progress-bar__outer,
.circle-video-thumbnail-hover .circle-progress-bar__outer {
  background: transparent;
}

.add-play-btn {
  position: relative;
  display: inline-block;
  cursor: pointer;
  width: 100%;
}

.add-play-btn:before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  content: "\\f01d";
  font-family: FontAwesome;
  font-size: 80px;
  color: #fff;
  opacity: 0.8;
  text-shadow: 0px 0px 30px rgb(0 0 0 / 50%);
}

.additional-tile-block {
  font-family: "FuturaPT-Heavy";
  font-size: 15px !important;
  color: #fff;
  margin: 3px;
  width: 100%;
  height: 152px;
  display: flex;
  text-transform: uppercase;
  text-align: center;
  justify-content: center;
  align-items: center;
  background-size: cover;
  min-width: 300px;
  min-height: 200px;
}

.progrees-bar-add-tile {
  width: 100%;
  margin-top: auto;
  height: 5px;
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .additional-tile-block {
    width: calc(20% - 6px);
  }
}

.modal-close {
  display: flex;
  justify-content: flex-end;
}

.circle-progress-bar__inner {
  max-width: 81px;
  height: 81px;
}
.play-icon {
  width: 65px;
  height: 100%;
  x: 14%;
  y: 0;
  cursor: pointer;
}
.progress-ring {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;
  width: 100%;
}
.progress-ring__circle,
.progress-ring__circle--front {
  transition: 0.35s stroke-dashoffset;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
  stroke-width: 7;
  cx: 50%;
  cy: 50%;
}
.progress-ring__circle {
  stroke: rgba(255, 255, 255, 0.73);
}
.progress-ring__circle--front {
  stroke: rgba(240, 37, 37, 0.73);
}

.bmpui-ui-settings-panel select {
  display: block !important;
  background-image: none !important;
}

.bmpui-ui-settings-panel .bmpui-ui-settings-panel-item {
  width: 170px !important;
}
/* LOOKUP */

.lookup .trasparent-addon {
  background-color: #fff;
}
.lookup .search-input {
  border-right: 0px solid #fff;
}

.lookup .card {
  box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.08);
}
.lookup .card label {
  line-height: 1;
}

.lookup .lookup-card--gray li {
  background-color: #f1f1f1;
}

.lookup-dropdown .truncate-name {
  width: 50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.lookup-dropdown .f-table,
table.f-table thead tr:last-child th {
  border: none;
}

.lookup-widget-card-height {
  max-height: 230px;
}

.lookup-widget-lg-screen img {
  margin: auto 0px;
  max-height: 215px;
}

@media (max-width: 1200px) {
  .lookup-widget-card-height {
    max-height: 500px;
  }
}

.lookup-widget-card-height.no-results-alert {
  max-height: 210px;
  margin-top: 5px !important;
}

@media (max-width: 1360px) {
  .lookup-widget .search-result-text {
    font-size: 14px;
  }
}

@media (max-width: 1200px) {
  .lookup-widget-lg-screen {
    display: none;
  }
}
@media (min-width: 1200px) {
  .lookup-widget-sm-screen {
    display: none;
  }
}

.lookup-table td {
  font-size: 14px !important;
}

/* SLIDESHOW */
.image-slideshow .image-container {
  text-align: center;
  padding: 0px 10px;
  border-radius: 5px;
  overflow: hidden;
  min-height: 120px;
}

.image-slideshow .image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  height: 200px;
  max-height: 200px;
}
.image-slideshow .image-container .main-image {
  max-width: 100%;
  max-height: 200px;
}

/* SLIDESHOW for Lookup */

.lookup-arrangement-slideshow {
  height: auto !important;
  max-height: 450px !important;
}

/* CAROUSEL */
.slideshow-carousel {
  padding: 0;
  text-align: center;
  margin: 10px 0 0;
}

.slideshow-carousel .carousel-image {
  width: 65px;
  min-height: 65px;
  margin: 3px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  padding: 6px;
  border: solid 2px #000;
  border-radius: 7px;
}

.slideshow-carousel .carousel-image a {
  border: solid 1px #333;
  width: 36px;
  height: 36px;
}

.slideshow-carousel .carousel-image.selected a {
  width: 30px;
  height: 30px;
}

.slideshow-carousel .carousel-image.selected {
  box-shadow: 0 0 0 1px #000;
}

.slideshow-carousel .carousel-image.selected:before {
  display: none;
}

.slideshow-carousel .carousel-image.selected:after {
  display: none;
}

.slideshow-carousel .carousel-image img {
  width: fit-content;
  height: fit-content;
  max-width: 100%;
  max-height: 100%;
}

.slideshow-carousel .carousel-image img.loading {
  max-width: 35px;
  max-height: 35px;
}

@media (max-width: 1199px) {
  .slideshow-carousel {
    margin: 10px 0;
  }
}

/* Zoom Image Modal */
.modal-close-btn {
  color: #cccccc;
  padding: 5px 11px;
  cursor: pointer;
  transition: all 0.2s ease;
}
.modal-close-btn:hover {
  color: #908d8d;
}
.modal-responsive {
  width: fit-content !important;
  max-width: 95%;
}

.yellow-banner {
  background-color: #ffc333;
  color: #212529;
  text-align: center;
  padding: 5px 10px;
  font-weight: normal;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
}

@media (max-width: 768px) {
  .yellow-banner {
    margin: 10px -15px; /* Assuming your card has 15px padding */
  }
}
